import React from 'react';
import _ from 'lodash';
import { CHANNELS } from '../config/constants';
import { MSG_STATUS } from '../models';
import { DoneIcon, DoneAllIcon, AlarmIcon, BlockIcon, ErrorIcon } from '../components/atoms';

const getStatusPriorityValue = (status) => {
  const priority = _.find(MSG_STATUS, ({ VALUE }) => VALUE === status);
  return _.get(priority, 'PRIORITY', -1);
};

export default class MessageStatusService {
  /**
   * @param status string representing the status from the sent message
   * @returns status icons in this order: {statusIcon, deletedIcon, errorIcon}
   */
  static getStatusIcon = (status, channel) => {
    // TODO: this is a component by itself. Extract it and change logic on the usage
    let statusIcon = null;
    let deletedIcon = null;
    let errorIcon = null;
    switch (status) {
      case MSG_STATUS.SENT.VALUE:
      case MSG_STATUS.TRYING_AGAIN.VALUE:
      case MSG_STATUS.QUEUED.VALUE:
        statusIcon = React.createElement(AlarmIcon, { className: 'info-icon' });
        break;
      case MSG_STATUS.CHANNEL_SENT.VALUE:
        statusIcon = React.createElement(DoneIcon, { className: 'info-icon' });
        break;
      case MSG_STATUS.CHANNEL_DELIVERED.VALUE:
        statusIcon = React.createElement(DoneAllIcon, { className: 'info-icon' });
        break;
      case MSG_STATUS.CHANNEL_READ.VALUE:
        statusIcon = React.createElement(DoneAllIcon, { className: 'info-icon read' });
        break;
      case MSG_STATUS.ERROR.VALUE:
      case MSG_STATUS.CHANNEL_ERROR.VALUE:
        errorIcon = React.createElement(ErrorIcon, { className: 'info-icon error' });
        break;
      case MSG_STATUS.CHANNEL_DELETED.VALUE:
        deletedIcon = React.createElement(BlockIcon, { className: 'info-icon' });
        break;
      default:
        break;
    }
    if (channel === CHANNELS.webchat) {
      statusIcon = null;
    }
    return { statusIcon, deletedIcon, errorIcon };
  };

  /**
   * @summary Checks the priority of the message status received when possibly
   * those statuses can come not ordered properly from WhatsApp
   * @param oldStatus last status received
   * @param newStatus newest status received
   * @returns the proper status
   */
  static checkPriorityStatus = (oldStatus, newStatus) => {
    const oldStatusPriority = getStatusPriorityValue(oldStatus);
    const newStatusPriority = getStatusPriorityValue(newStatus);

    const isNewerStatus = oldStatusPriority <= newStatusPriority;
    return isNewerStatus ? newStatus : oldStatus;
  };
}
