import styled from 'styled-components';

export default styled.button`
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  margin-left: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[400]};
    cursor: pointer;
    .button-icon {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondary.main};
    .button-icon {
      fill: white;
    }
  }

  .button-icon {
    position: relative;
    top: 1px;
    fill: ${({ theme }) => theme.colors.grey[200]};
  }
`;
