import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import bp from '../../../config/breakpoints';
import AgentConversationCardStyled from './styled';
import { UserIcon, ChatCellInfo, VisibilityIcon, IconButton, TransferConvIcon, CloseConvIcon, WhatsappIcon, WebchatIcon, MessengerIcon } from '../../atoms';
import i18n from '../../../assets/i18n';

const AgentConversationCard = ({ data, hideSeeButton, onSeeConversationClick, onCloseConversationClick, onTransferConversationClick }) => {
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });
  const { conversationSessionId, profileName, lastMessageReceived, lastMessageSent, contactId, channel } = data;
  const [timeAgoLastMessageReceived, setTimeAgoLastMessageReceived] = useState('');
  const [timeAgoLastMessageSent, setTimeAgoLastMessageSet] = useState('');
  const [lastMessageRecievedISOString, setLastMessageRecievedISOString] = useState('');
  const [lastMessageSentISOString, setLastMessageSentISOString] = useState('');
  const [channelIcon, setChannelIcon] = useState(null);

  const updateTimeInterval = 60 * 1000;
  const isContactId = contactId && channel === 'whatsapp';

  useEffect(() => {
    setTimeAgoLastMessageReceived(moment(lastMessageReceived).fromNow());
    setTimeAgoLastMessageSet(moment(lastMessageSent).fromNow());
    setLastMessageRecievedISOString(lastMessageReceived);
    setLastMessageSentISOString(lastMessageSent);

    const interval = setInterval(() => {
      setTimeAgoLastMessageReceived(moment(lastMessageRecievedISOString).fromNow());
      setTimeAgoLastMessageSet(moment(lastMessageSentISOString).fromNow());
    }, updateTimeInterval);

    return () => clearInterval(interval);
  }, [lastMessageReceived, lastMessageSent, lastMessageRecievedISOString, lastMessageSentISOString, updateTimeInterval]);

  useEffect(() => {
    if (data.channel === 'whatsapp') {
      setChannelIcon(
        <div data-tip={i18n.chats.whatsapp} data-place="left" data-tip-disable={isMediumScreen}>
          <WhatsappIcon className="agent-conversation-card--channel-icon-holder--icon" />
        </div>
      );
    } else if (data.channel === 'messenger') {
      setChannelIcon(
        <div data-tip={i18n.chats.messenger} data-place="left" data-tip-disable={isMediumScreen}>
          <MessengerIcon className="agent-conversation-card--channel-icon-holder--icon" />
        </div>
      );
    } else {
      setChannelIcon(
        <div data-tip={i18n.chats.webchat} data-place="left" data-offset="{'left': 10}" data-tip-disable={isMediumScreen}>
          <WebchatIcon className="agent-conversation-card--channel-icon-holder--icon" />
        </div>
      );
    }
  }, [data.channel, isMediumScreen]);
  return (
    <>
      <AgentConversationCardStyled>
        <div className="agent-conversation-card--info">
          {/* <UserIcon fill="#CECECE" /> */}
          <div className="agent-converstation-card--info--text">
            <ChatCellInfo row title={`${i18n.chats.singleConversation} #${isContactId ? `+${contactId}` : conversationSessionId}`} profileName={profileName} />
            {lastMessageReceived && (
              <p className="agent-converstation-card--info--text--subtitle">
                {i18n.supervisor.lastMessageReceived} {timeAgoLastMessageReceived}
              </p>
            )}
            {lastMessageSent && (
              <p className="agent-converstation-card--info--text--subtitle">
                {i18n.supervisor.lastMessageSent} {timeAgoLastMessageSent}
              </p>
            )}
          </div>
        </div>
        <div className="agent-conversation-card--channel-icon-holder">{channelIcon}</div>
        <div className="agent-conversation-card--buttons-row">
          {!hideSeeButton && (
            <IconButton
              toolTip={i18n.supervisor.seeMessages}
              disableToolTip={isMediumScreen}
              onClick={(e) => {
                e.preventDefault();
                onSeeConversationClick(conversationSessionId);
              }}
            >
              <VisibilityIcon className="button-icon ts ts-normal" />
            </IconButton>
          )}
          <IconButton
            toolTip={i18n.chats.transferConvToolTip}
            disableToolTip={isMediumScreen}
            onClick={() => onTransferConversationClick(conversationSessionId)}
          >
            <TransferConvIcon className="button-icon ts ts-normal" />
          </IconButton>
          <IconButton toolTip={i18n.chats.endconvToolTip} disableToolTip={isMediumScreen} onClick={() => onCloseConversationClick(conversationSessionId)}>
            <CloseConvIcon className="button-icon ts ts-normal" />
          </IconButton>
        </div>
      </AgentConversationCardStyled>
      <ReactTooltip type="info" />
    </>
  );
};

AgentConversationCard.propTypes = {
  data: PropTypes.object.isRequired,
  onSeeConversationClick: PropTypes.func,
  onCloseConversationClick: PropTypes.func,
  onTransferConversationClick: PropTypes.func,
  hideSeeButton: PropTypes.bool,
};

export default AgentConversationCard;
