import styled from 'styled-components';

const EventFlowMessageBubbleStyled = styled.li`
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  ${({ isThereErrorIcon }) => (isThereErrorIcon ? 'padding-right: 40px;' : '')}
  text-align: ${({ sendType }) => ((sendType === 'operator' || sendType === 'proactive') ? 'right' : 'left')};

  .error-icon-holder {
    position: absolute;
    top: 20px;
    right: 0;
    cursor: pointer;
    .info-icon {
      width: 28px;
      height: 28px;
      margin-left: 8px;
      margin-bottom: 4px;
    }
    .error {
      fill: ${({ theme }) => theme.colors.red};
    }
  }

  .message-text {
    white-space: pre-wrap;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .info-icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      margin-bottom: 4px;
      fill: gray;
    }
    .read {
      fill: ${({ theme }) => theme.colors.checkMarkBlue};
    }
    .error {
      fill: ${({ theme }) => theme.colors.red};
    }
  }

  .deleted-info-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    padding-right: 8px;

    .info-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      margin-bottom: 4px;
      fill: gray;
    }
    .deleted-text {
      color: gray;
      font-size: 14px;
      font-style: italic;
    }
  }
`;

export { EventFlowMessageBubbleStyled };
