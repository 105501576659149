import styled from 'styled-components';

const ChatCellInfoStyled = styled.div`
  ${({ row }) =>
    row
      ? `display: flex;
        align-items: center;
        flex-direction: 'column';
        `
      : `display: block;
  flex: 1;
  margin-left: 16px;`}

  p {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  small {
    font-size: 12px;
    ${({ row }) => (row ? 'margin-left: 8px' : '')}
    font-style: italic;
    color: ${(props) => props.theme.colors.grey[200]};
    line-height: 24px;
  }
`;
const TitleStyled = styled.span`
  overflow-x: hidden;
  max-width: 190px;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 24px;
`;
const AliasStyled = styled.small`
  margin-left: 10px;
  vertical-align: middle;
`;

export { ChatCellInfoStyled, TitleStyled, AliasStyled };
