import styled from 'styled-components';

export const TagContainer = styled.div`
  max-width: ${(props) => props.maxWidth}px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 0px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
`;

export const Tag = styled.span`
  background-color: ${(props) => props.color};
  color: #fff;
  padding: 3px;
  margin-right: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 85%;
`;

export const MoreTag = styled.span`
  background-color: #ddd;
  padding: 3px;
  margin-left: 5px;
  border-radius: 3px;
  font-size: 85%;
`;

export const Popup = styled.div`
  position: absolute;
  ${(props) => (props.direction === 'down' ? 'top: 100%;' : 'bottom: 100%;')}
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 5px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  ${(props) => (props.direction === 'down' ? 'margin-top: 10px;' : 'margin-bottom: 0px;')}
`;

export const PopupTag = styled.div`
  background-color: ${(props) => props.color};
  color: #fff;
  padding: 3px;
  margin-right: auto;
  font-size: 85%;
  margin-bottom: 5px;
  border-radius: 3px;
`;
