import React from 'react';
import PropTypes from 'prop-types';


export const InteractiveExtraContent = ({ data }) => {
  const { action } = data;
  const buttons = action?.buttons;
  return (
    <div className="interactive-extra-content">
      {buttons ?
            <div className="interactive-wrapper">
              {buttons?.length && buttons.map((el, i) => {
                const valueButton = el?.type && el[el.type] && el[el.type]?.title;
                return (
                  valueButton ? <div key={i} className="button-interactive">
                    <span>{valueButton}</span>
                  </div> : <></>
                )
              }
              )}
            </div> : <></>
      }
    </div>
  )
};

InteractiveExtraContent.propTypes = {
  data: PropTypes.object,
};
