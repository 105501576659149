import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import AgentDetailPanelStyled from './styled';
import { useSocketContext } from '../../../sockets/context';
import { AgentConversationCard, ModalTransferAgents, Modal } from '../../molecules';
import { supervisorSubscribeToConversation, closeConversation, supervisorTransferConversation } from '../../../sockets/emit';
import Paths from '../../../config/routes';
import i18n from '../../../assets/i18n';
import { MODAL_TYPE } from '../../../models';
import { BackButton } from '../../atoms';

const AgentConversationsList = ({ conversations, onSeeConversationClick, onCloseConversationClick, onTransferConversationClick }) => {
  const history = useHistory();
  return (
    <ul className="agent-detail-panel--list-container">
      {conversations?.map((conv) => (
        <AgentConversationCard
          key={conv.conversationSessionId}
          data={conv}
          onSeeConversationClick={onSeeConversationClick}
          onCloseConversationClick={onCloseConversationClick}
          onTransferConversationClick={onTransferConversationClick}
        />
      ))}
      <BackButton onClick={() => history.goBack()} />
    </ul>
  );
};

AgentConversationsList.propTypes = {
  conversations: PropTypes.arrayOf(PropTypes.object),
  onSeeConversationClick: PropTypes.func,
  onCloseConversationClick: PropTypes.func,
  onTransferConversationClick: PropTypes.func,
};

const AgentDetailPanel = () => {
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [selectedConversationSessionId, setSelectedConversationSessionId] = useState(-1);
  const { agentId } = useParams();
  const history = useHistory();

  const { supervisorAgentStatus, addSubscribedSupervisorConv } = useSocketContext();
  const agentStats = supervisorAgentStatus.find((agent) => {
    return agent.agentId === parseInt(agentId, 10);
  });
  let convsFromStats = [];
  convsFromStats = agentStats && agentStats.conversations;

  const handleSeeConversationClick = (conversationSessionId) => {
    supervisorSubscribeToConversation(conversationSessionId);
    addSubscribedSupervisorConv(conversationSessionId);
    history.push(Paths.SUPERVISOR_CONVERSATION_DETAIL.replace(':agentId', agentId).replace(':conversationId', conversationSessionId));
  };

  const handleCloseConversationClick = (conversationSessionId) => {
    setSelectedConversationSessionId(conversationSessionId);
    setShowCloseModal(true);
  };

  const onCloseConversation = () => {
    closeConversation(selectedConversationSessionId);
    setShowCloseModal(false);
  };

  const handleModalCancel = () => setShowCloseModal(false);

  const [showTransferModal, setShowTransferModal] = useState(false);
  const toggleShowTransferModal = () => setShowTransferModal(!showTransferModal);

  const handleTransferConversationClick = (conversationSessionId) => {
    setSelectedConversationSessionId(conversationSessionId);
    setShowTransferModal(true);
  };

  const handleFinishModalTransfer = (transferAgentId) => {
    toggleShowTransferModal();

    supervisorTransferConversation(selectedConversationSessionId, transferAgentId);
  };

  const agentName = agentStats ? agentStats.name : i18n.supervisor.notAvailableAKA;

  return (
    <AgentDetailPanelStyled>
      <p className="agent-card-detail--title">{i18n.formatString(i18n.supervisor.conversationsOf, { agentName })}</p>
      {_.isEmpty(convsFromStats) ? (
        <>
          <p className="agent-card-detail--title">{i18n.supervisor.noConversationsToShow}</p>
          <div className="agent-detail-panel--list-container">
            <BackButton onClick={() => history.goBack()} />
          </div>
        </>
      ) : (
        <AgentConversationsList
          conversations={convsFromStats}
          onSeeConversationClick={handleSeeConversationClick}
          onCloseConversationClick={handleCloseConversationClick}
          onTransferConversationClick={handleTransferConversationClick}
        />
      )}
      <ModalTransferAgents
        show={showTransferModal}
        onModalCancel={toggleShowTransferModal}
        onModalFinish={handleFinishModalTransfer}
        conversationSessionId={selectedConversationSessionId}
      />
      <Modal
        action={MODAL_TYPE.CLOSE_CONV}
        show={showCloseModal}
        onModalCancel={handleModalCancel}
        onModalFinish={onCloseConversation}
      />
    </AgentDetailPanelStyled>
  );
};

export default AgentDetailPanel;
