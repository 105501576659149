import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { ErrorIcon } from '../../../atoms';
import i18n from '../../../../assets/i18n';
import { BulletedListIcon } from '../../../atoms/svg-icons';
 

export const MessageInteractiveHolder = ({ isFetching, isDownloadError, data }) => {
  const { type, action, body, footer, header } = data;
  return (
    <div className="interactive-holder">
      {isFetching && <ClipLoader size={40} loading={isFetching} />}
      {isDownloadError ? (
        <>
          <ErrorIcon className="error-icon" />
          <p className="bubble-info-text">{i18n.errors.interactiveNotAvailable}</p>
        </>
      ) : (
        data &&
          <>
          <div className="interactive-wrapper">
            {header?.text ? <div className="interactive-header-wrapper">
              <p className="interactive-header-text">{header?.text}</p>
            </div> : <></>}
            {body?.text ? <div className="interactive-body-wrapper">
              <p className="interactive-body-text">{body?.text}</p>
            </div> : <></>}
            {footer?.text ? <div className="interactive-body-wrapper">
              <a className="interactive-body-footer" href={footer?.text}>{footer?.text}</a>
            </div> : <></>}
            {type === 'list' && (
              <div className="interactive-bottom-list">
                <p className="interactive-icon"><BulletedListIcon /></p>
                <p className="interactive-bottom-list-text">{action?.button}</p>
              </div>
            )}
          </div>
          </>
      )}
    </div>
  )
};

MessageInteractiveHolder.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  data: PropTypes.object,
};
