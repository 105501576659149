export const replacePlaceholders = (obj, variables) => {
  if (obj.type === "BODY" || obj.type === "HEADER") {
    let message = obj.text;
    const placeholders = message.match(/\*([^*]+)\*/g);

    if (placeholders) {
      placeholders.forEach((placeholder) => {
        const text = placeholder.slice(1, -1);
        message = message.replace(placeholder, `<strong>${text}</strong>`);
      });
    }

    const replacedMessage = message.replace(/{{\d+}}/g, (match) => {
      const index = parseInt(match.match(/\d+/)[0], 10);
      return variables?.parameters?.[index - 1]?.text || "";
    });

    return replacedMessage;
  }

  if (obj.type === "URL") {
    const placeholder = "{{1}}";
    const value = obj.example[0];
    let { url } = obj;
    url = url.replace(placeholder, value);
    return url;
  }

  return "";
};