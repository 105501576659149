import React from 'react';
import styled from 'styled-components';

const AiOutlineDown = () => {
  return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 10l5 5 5-5H7z" fill="currentColor"/>
</svg>)
}
export const customStyles = {



  multiValue: (provided, state) => {
    const color = state.data.code || '#ccc'; // Usa el color de la etiqueta o un color por defecto

    return {
        ...provided,
        backgroundColor: color, // Aplica el color personalizado
        color: '#fff', // Color del texto
    };
  },
  multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
  }),



  control: (provided) => ({
    ...provided,
    width: '100%',
    flex: 1,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  }),
};

export const TagManagerWrapper = styled.div`
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-right: 30px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 6px 12px;
  cursor: default;
  &:hover {
    background-color: #f0f0f0;
    color: #333;
  }
`;

export const DropdownMenu = styled.div`
  position: fixed;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

export const MenuItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const StyledEditionInput = styled.input`
  margin-top: 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

export const StyledAiOutlineDown = styled(AiOutlineDown)`
  display: inline-block;
  width: 19px;
  height: 15px;
  margin-left: 5px;
  margin-right: 5px;
`;