import * as api from '../api';
import CacheService from './cache-service';

class ResourcesService {
  getResource = async (id) => {
    if (CacheService.isCached(id)) {
      return CacheService.getData(id);
    }
    try {
      const payload = await api.getMultimediaResource(id);
      const payloadURL = this.getPayloadURL(payload);
      CacheService.add(id, payloadURL);
      CacheService.toString();
      return CacheService.getData(id);
    } catch (error) {
      throw new Error(error);
    }
  };

  getPayloadURL = (payload) => {
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(payload);
  };
}

const instance = new ResourcesService();

export default instance;
