import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { QuickReplyMessageBubbleStyled } from './styled';
import i18n from '../../../assets/i18n';

const QuickReplyMessageBubble = ({ colorClass, message, date, sendType, statusIcon, deletedIcon, errorIcon }) => (
    <QuickReplyMessageBubbleStyled sendType={sendType} isThereErrorIcon={!_.isNil(errorIcon)}>
      {errorIcon && (
        <div className="error-icon-holder" data-tip={i18n.errors.tooltipError} data-offset="{'left': 5}">
          {errorIcon}
        </div>
      )}
      <div className={colorClass}>
        {deletedIcon ? (
          <div className="deleted-info-holder">
            {deletedIcon}
            <p className="deleted-text">{message}</p>
          </div>
        ) : (
          <>
            <p className="message-text">{message}</p>
            <div className="info-row">
              <p className="timespan-message">{date}</p>
              {statusIcon}
            </div>
          </>
        )}
      </div>
    </QuickReplyMessageBubbleStyled>
);

QuickReplyMessageBubble.propTypes = {
  colorClass: PropTypes.string,
  message: PropTypes.string,
  date: PropTypes.string,
  sendType: PropTypes.string,
  statusIcon: PropTypes.any,
  deletedIcon: PropTypes.any,
  errorIcon: PropTypes.any,
};

export default QuickReplyMessageBubble;
